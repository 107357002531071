
import { staticPath, siteUrl } from '@/data/helpers';
import LazyImage from '@/components/LazyImage';

export default {
  methods: {
    staticPath,
    siteUrl
  },
  components: {
    LazyImage
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {

  }
};
